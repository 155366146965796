<template>
  <div v-if="canAccess('rp_informe_verify')" class="card">
    <BlockUI :blocked="loading" :fullScreen="true" style="position:absolute;top:50%;left:50%;">
      <i v-if="loading" class="pi pi-spin pi-spinner" style="font-size: 3em;"></i>
    </BlockUI>
    <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items"/>

    <div class="p-grid p-fluid dashboard">
      <div class="p-col-12">
        <h6>Informe por verificador</h6>
      </div>
      <div class="p-col-12">
        <div class="p-text-right p-fluid  p-ml-2">
          <div class="p-grid p-fluid  p-ml-2">
            <div style="padding-top: 20px;" class="p-col-12 p-md-2 p-mt-2">
                <span class="p-float-label df">
                     <Dropdown v-model="employee" :options="employees" optionLabel="name" :filter="true" filterPlaceholder="Verificador"/>
                    <label for="subsidiary">Verificador</label>
                </span>
            </div>
            <div style="padding-top: 20px;" class="p-col-12 p-md-2 p-mt-2">
              <span class="p-float-label df" style="margin-right: 3px">
                    <Calendar autocomplete="off" :maxDate="new Date()" aria-autocomplete="false"
                              id="start_date" :showIcon="true" v-model="date_start"
                              dateFormat="yy-mm-dd"
                              :yearNavigator="true" yearRange="2019:2040"/>
                    <label for="start_date">Fecha Inicio</label>
              </span>
            </div>
            <div style="padding-top: 20px;" class="p-col-12 p-md-2 p-mt-2">
              <span class="p-float-label df" style="margin-left: 3px">
                  <Calendar :maxDate="new Date()" :minDate="date_start" autocomplete="off"
                            id="end_date" :showIcon="true" v-model="date_end" dateFormat="yy-mm-dd"
                            :yearNavigator="true" yearRange="2019:2040" class="p-inputtext-sm"/>
                  <label for="end_date">Fecha Fin</label>
              </span>
            </div>
            <div style="padding-top: 20px;" class="p-col-12 p-md-2 p-mt-2">
                <span class="p-float-label df">
                  <Dropdown v-model="estado" :options="statuses" optionLabel="label" />
                    <label for="status">Estado</label>
                </span>
            </div>
            <div style="padding-top: 20px;" class="p-col-12 p-md-2 p-mt-2">
              <Button label="Buscar" class="p-button-success"
                      @click="getData()"/>
            </div>
            <div style="padding-top: 20px;" class="p-col-12 p-md-1 p-mt-2">
              <Button label="XLS" class="p-button-secondary"
                      @click="exportReport('XLS')"/>

            </div>
            <div style="padding-top: 20px;" class="p-col-12 p-md-1 p-mt-2">
              <Button label="PDF" class="p-button-info"
                      @click="exportReport('PDF')"/>

            </div>

          </div>
        </div>
      </div>
      <div class="p-col-12">
        <DataTable :value="tbData"  class="p-datatable-responsive"
                   :paginator="true"
                   paginatorPosition="bottom" :rows="10">
          <Column field="matricula" header="Matrícula" :sortable="true"></Column>
          <Column field="apellidos" header="Apellidos" :sortable="true"></Column>
          <Column field="nombres" header="Nombres" :sortable="true"></Column>
          <Column field="filial" header="Filial" :sortable="true"></Column>
          <Column field="estado" header="Estado" :sortable="true"></Column>


          <Column field="registro" header="Ingreso" :sortable="true">
            <template #body="slotProps">
              {{ $filters.dateFormat(slotProps.data['registro']) }}
              <span :title="$filters.dateFormat(slotProps.data['reubicacion'])" class="p-badge p-badge-warning p-m-1" v-if="slotProps.data['reubicacion']">
                Reubicada
              </span>
            </template>
          </Column>

          <Column field="verificacion" header="Verificación" :sortable="true"></Column>
          <Column field="verificador" header="Verificador" :sortable="true"></Column>
        </DataTable>
      </div>
    </div>
  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button label="Regresar" class="p-button-danger p-mr-2"
              @click="$router.push('/admin')"/>
    </div>
  </div>
</template>

<script>

import {mapGetters, mapState} from "vuex";
import bouncer from "../../../helpers/bouncer";
import service from "../service/report.service";
import moment from "moment";
import FileSaver from 'file-saver';
export default {
  mixins: [bouncer],
  name: "VerificationReport",
  data() {
    return {
      statuses:[
        {'id':'OK', 'label':'OK'},
        {'id':'Pendiente', 'label':'PENDIENTES'},
        {'id':'Rechazada', 'label':'RECHAZADAS'}
      ],
      date_end: null,
      date_start: null,
      estado: null,
      employee:null,
      tbData:[],
      employees:[],
      service: service('verification-enrollment'),
    }
  },
  mounted() {
    if (this.canAccess('rp_informe_verify')) {
      this.$store.dispatch('bread/setItems', [
        {label: 'Estadísticas', url: "javascript:void(0);"}]);

      const date = new Date();
      this.date_end = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
      this.date_start = new Date(date.getFullYear(), date.getMonth(), 1);
      this.service.getInfVerify({
        'estado':this.estado?.id,
        'verificador_id': this.employee?.id,
        'date_start': this.date_start,
        'date_end': this.date_end
      }).then(response => {
        this.employees=response.data?.verificadores??[];
        this.tbData=response.data?.dataReport??[];
        this.loading = false
      });

    }
  },
  methods: {
    getData(){
      this.service.getInfVerify({
        'estado':this.estado?.id,
        'verificador_id': this.employee?.id,
        'date_start': this.date_start,
        'date_end': this.date_end
      }).then(response => {
        this.tbData=response.data?.dataReport??[];
        this.loading = false;
      });
    },
    exportReport(command) {
      if(this.estado?.id==null || this.employee?.id==null){
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Todos los campos son obligatorios',
          life: this.$utils.toastLifeTime()
        });
        return;
      }
      this.loading = true;
      this.service.getInfVerify({
        date_start: moment(this.date_start).format("YYYY-MM-DD"),
        date_end: moment(this.date_end).format("YYYY-MM-DD"),
        estado:this.estado?.id,
        verificador_id: this.employee?.id,
        export: command
      }).then(x => {
            if(command==='PDF'){
              this.info = x.data;
              var byteCharacters = atob(x.data);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], {type: "application/pdf"});
              this.loading = false;
              FileSaver.saveAs(blob, "NaturaEnglishReport.pdf");
            }else{
              window.open(x.data.url_file)
            }
          }).catch((err) => {
        const message = err.response.data;
        let error = this.$utils.formatError(message);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      })

    },
  },
  computed: {
    ...mapState('auth', ['isAdmin']),
    ...mapGetters('bread', ['breadcrumb']),
  }
}
</script>

<style>

.p-dropdown .p-dropdown-trigger {
  height: 33px;
}

.thReport {

  padding: 2px !important;
  border-width: 1px !important;
}

.tdReport {
  padding: 2px !important;
  border-width: 1px !important;
}

div.tableC > div > div > table {
  table-layout: auto !important;
}

.df {
  margin-left: 3px !important;
  margin-right: 4px !important;

}

span.p-calendar.p-component.p-inputwrapper.p-calendar-w-btn.p-inputwrapper-filled {
  height: 35px !important;
}

.p-dropdown-label {
  text-align: left;
}
</style>
